<template>
    <modal name="DeleteCardModalNew" width="308px" height="160px" @before-open="onBeforeOpen">
        <div class="modal--view">
            <div class="modal--head">
                <a @click="showEditCardModal"><span class="icon-close"></span></a>
            </div>
            <div class="modal--body">
                <div class="title--container">
                    <p>Вы уверены, что хотите отвязать карту?</p>
                </div>
                <div class="button--container">
                    <button class="close__button" @click="showEditCardModal">ОТМЕНА</button>
                    <button @click="deleteCard">ОК</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "DeleteCardModalNew",
        data(){
          return {
              activeCard : null
          }
        },
        methods:{
            onBeforeOpen(e){
                this.activeCard = {...e.params.activeCard}
            },

            onClose(){
                this.$modal.hide("DeleteCardModalNew");
            },

            showEditCardModal(){
                this.onClose();
                this.$modal.show("EditCardModalNew",{value: this.activeCard})
            },

            deleteCard(){
                this.onClose();
                this.$emit("delete",this.activeCard);
            },
        }
    }
</script>

<style scoped lang="scss">
    .modal--view{
        width: 308px;
        height: 160px;
        background: #FFFFFF;
        border: 0 solid #F2F2F2;
        box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .modal--head{
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;
            .icon-close{
                margin-right: 16px;
                cursor: pointer;
                color: rgb(161,160,161);
            }


            a{
                &:hover{
                    filter: brightness(87.5%);
                }
            }
        }

        .modal--body{
            width: 100%;
            .title--container{
                margin-top: 19px;
                width: 100%;
                p{
                    font-family: Roboto, "sans-serif";
                    font-weight: 300;
                    font-size: 14px;
                    color: #000000;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 24px;
                }
            }

            .text--container{
                margin-top: 13px;
                p{
                    font-family: Roboto, "sans-serif";
                    font-weight: 300;
                    font-size: 14px;
                    color: #141213;
                    line-height: 21px;
                }
            }

            .button--container{
                margin-top: 42px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                button{
                    font-family: Roboto, "sans-serif";
                    font-weight: 400;
                    font-size: 12px;
                    color: #CA4EBB;
                    letter-spacing: 2px;
                    text-align: center;
                    cursor: pointer;
                    border: 0;
                    outline: 0;
                    margin-right: 15px;
                    padding-left: 21px;
                    padding-right: 21px;
                    height: 32px;
                    border-radius: 8px;
                    background-color: #F1F1F1;
                    &:hover{
                        filter: brightness(87.5%);
                    }
                }

                .close__button{
                    font-family: Roboto, "sans-serif";
                    font-weight: 500;
                    font-size: 12px;
                    color: black;
                    letter-spacing: 2px;
                    text-align: center;
                    cursor: pointer;
                    border: 0;
                    outline: 0;
                    margin-right: 10px;


                    padding-left: 21px;
                    padding-right: 21px;
                    height: 32px;
                    border-radius: 8px;
                    background-color: #F1F1F1;
                    &:hover{
                        filter: brightness(87.5%);
                    }
                }
            }
        }
    }
</style>