






























import {Component, Vue} from "vue-property-decorator";
import CircleImageButton from "@/components/CircleImageButton.vue";
import {ActiveCard} from "@/entity/ActiveCard";

@Component({
    components: {CircleImageButton}
})
export default class EditCardModalNew extends Vue{

    private activeCard:ActiveCard = new ActiveCard(0,"","",0,false);


    onBeforeOpen(e:any){
        this.activeCard = e.params.value
    }

    onClose(){
        this.$modal.hide("EditCardModalNew");
    }

    showDeleteCardModal(){
        this.onClose();
        this.$modal.show("DeleteCardModalNew",{activeCard:this.activeCard});
    }
}
